import axiosInstance from "../../api/Axios";
import { useAuth } from "../AuthContext";

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        try {
            const response = await axiosInstance.get('api/logout/', {
                withCredentials: true
            });
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };

    return logout;
}

export default useLogout;