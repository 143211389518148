import { axiosInstance } from "../../api/Axios";
import { useAuth } from "../AuthContext"

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axiosInstance.get('api/token/refresh/', {
            withCredentials: true
        });
        setAuth(prev => {
            return {
                ...prev,
                access: response.data.access,
                role: response.data.role,
                username: response.data.username
            }
        });
        return response.data.access;
    };
    return refresh;
};

export default useRefreshToken;
