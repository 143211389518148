import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import rolesPermissions from '../context/Roles';

// Helper function to check if the user has the required permissions
const checkPermissions = (role, feature, action) => {
  if (!feature || !action) return true; // If no feature/action provided, allow access (for general routes)
  return rolesPermissions[role]?.[feature]?.[action];
};

const PrivateRoute = ({ feature, action }) => {
  const { auth } = useAuth();
  const location = useLocation();

  const isAuthenticated = !!auth?.role;

  const isAuthorized = isAuthenticated && checkPermissions(auth.role, feature, action);

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (!isAuthorized) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
