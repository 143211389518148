import React from 'react'
import '../styles/Servicios/LogisticsServices.css'
//Images-Icons
import delivery from '../assets/vectores/delivery.png'
import logoLLC from '../assets/logo/logoLLC.png'
import almacenar from '../assets/vectores/almacenar.png'
import soporte from '../assets/vectores/soporte.png'


function LogisticsServices() {
  return (
    <section>
      <h3 className='logistics'>LOGÍSTICA</h3>
      <div className='logistics-container'>
        <div className='logistics-sevices-first'>
          <div className='logisticsIcons-container'>
            <img src={delivery} alt="logisticsIcons" className='logisticsIcons'/>
          </div>
          <div className='logistics-text'>
            <p>
            Amplia experiencia en gestión de envíos desde Asia, India, Indonesia y Estados Unidos.
            </p>
          </div>
        </div>
        <div className='logistics-sevices-second'>
          <div className='logisticsIcons-container'>
            <img src={logoLLC} alt="logisticsIcons" className='logisticsIcons'/>
          </div>
          <div className='logistics-text'>
            <p>
            Contratos de fletes y apoyo con seguros a través de Nutrimarket LLC en EE.UU.
            </p>
          </div>
        </div>
        <div className='logistics-sevices-first'>
          <div className='logisticsIcons-container'>
            <img src={almacenar} alt="logisticsIcons" className='logisticsIcons'/>
          </div>
          <div className='logistics-text'>
            <p>
            Almacenaje y tránsito con contrato outsourcing para toda la cadena de servicios.
            </p>
          </div>
        </div>
        <div className='logistics-sevices-second'>
          <div className='logisticsIcons-container'> 
            <img src={soporte} alt="logisticsIcons" className='logisticsIcons'/>
          </div>
          <div className='logistics-text'>
            <p>
            Apoyo en procesos de reclamos con asistencia entre el cliente y la fábrica.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LogisticsServices