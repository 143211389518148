import React, {useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Button, Col, Row} from 'react-bootstrap';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import '../../appstyles/ClientsCSS/ClientsForm.css';

// Validation schema
const RequiredString = Yup.string().required('Este campo es obligatorio');

const userSchema = Yup.object().shape({
    auth_user: Yup.object().shape({
        first_name: RequiredString,
        last_name: RequiredString,
    }),
    document_id: Yup.string()
    .required('Este campo es obligatorio')
    .matches(/^\+?\d+$/, 'Debe ser un número'),
    document_type: RequiredString,
    role: RequiredString,
    email: Yup.string().email('Formato invalido').required('Este campo es obligatorio'),
});

const UserForm = () => {
    const axiosInstance = useAxiosPrivate();
    const {userId} = useParams();
    const navigate = useNavigate();

    const defaultValues = {
        document_type: 'CC',
        role: '',
    };

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(userSchema),
        defaultValues
    });

    useEffect(() => {
        if (userId) {
          // Fetch user data for editing
          const fetchUserData = async () => {
            try {
              const response = await axiosInstance.get(`/api/user/${userId}/`);
              reset(response.data);
            } catch (error) {
              console.error('Error fetching user data:', error);
            }
          };
    
          fetchUserData(userId);
        }
    }, [userId, reset, axiosInstance]);  

    const onSubmit = async (data) => {
        try {

            if (userId) {
                // Update existing user
                const response = await axiosInstance.put(`api/user/${userId}/`, data); 
                console.log("User updated successfully!", response.data);
        
                navigate(`/settings/userdetails/${userId}`)
        
            } else {
                // Create new user
                const response = await axiosInstance.post('api/user/', data);
                console.log("User created successfully!", response.data);

                reset();
                setTimeout(() => {
                    navigate(`/settings`);
                }, 1000); 
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='maestros-form'>
        <h2>{userId ? 'Editar Usuario: Personal de la empresa' : 'Crear Usuario: Personal de la empresa'}</h2> 
        <Form onSubmit={handleSubmit(onSubmit)}>
        <hr />
        <h5>Información del Usuario</h5>
          <Row>
            {/* Primera Columna*/}
            <Col>

            <Form.Group controlId="first_name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control 
                    type="text" 
                    {...register('auth_user.first_name')} 
                    isInvalid={!!errors.auth_user?.first_name} 
                    disabled={!!userId}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.auth_user?.first_name?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="last_name">
                <Form.Label>Apellido</Form.Label>
                <Form.Control 
                    type="text" 
                    {...register('auth_user.last_name')} 
                    isInvalid={!!errors.auth_user?.last_name} 
                    disabled={!!userId}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.auth_user?.last_name?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control 
                    type="email" 
                    {...register('email')} 
                    isInvalid={!!errors.email} 
                    disabled={!!userId}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                </Form.Control.Feedback>
            </Form.Group>
    
            </Col>

            {/* Segunda Columna*/}
            <Col>
            <Form.Group controlId="document_type">
              <Form.Label>Tipo de documento</Form.Label>
              <Form.Select 
                aria-label="Default select example" 
                {...register('document_type')} 
                isInvalid={!!errors.document_type}
                disabled={!!userId}
            >
                  <option value="NIT">NIT</option>
                  <option value="CC">Cedula de Ciudadania (CC)</option>
                  <option value="TAX ID">Tax ID</option>
                  <option value="OTHER">Otro</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                      {errors.document_type?.message}
              </Form.Control.Feedback>
            </Form.Group>
    
            <Form.Group controlId="document_id">
              <Form.Label>Número de documento</Form.Label>
              <Form.Control 
                type="text" 
                {...register('document_id')} 
                isInvalid={!!errors.document_id} 
                disabled={!!userId}
            />
              <Form.Control.Feedback type="invalid">
                    {errors.document_id?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="role">
                <Form.Label>Cargo</Form.Label>
                <Form.Select aria-label="Default select example" {...register('role')} isInvalid={!!errors.role}>
                  <option value="ADMIN">Administrador</option>
                  <option value="DEV">Desarrollador</option>
                  <option value="CEO">CEO</option>
                  <option value="DTCA">Director de Calidad</option>
                  <option value="DTLO">Director de Logística</option>
                  <option value="DTFI">Director Financiero</option>
                  <option value="ANAD">Analista Administrativo</option>
                  <option value="DTAD">Director Administrativo</option>
                  <option value="DTCO">Director Comercial</option>
                  <option value="ASCO">Asesor Comercial</option>
                  <option value="ANCO">Analista de Compras</option>
                  <option value="AUBO">Auxiliar de Bodega</option>
                  {/*<option value="CLI">Cliente</option>*/}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.role?.message}
                </Form.Control.Feedback>
            </Form.Group>

            </Col>
        </Row>

        <Button type="submit">
            {userId ? 'Editar Usuario' : 'Crear Usuario'}
        </Button>

        </Form>
        </div>
    );
};

export default UserForm;