import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
import PermissionWrapper from '../../context/PermissionWrapper';

function ClientsDetails() {
  const axiosInstance = useAxiosPrivate();
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case 'H':
        return 'Alta';
      case 'M':
        return 'Media';
      case 'L':
        return 'Baja';
      default:
        return 'Desconocida';
    }
  };

  useEffect(() => {
    console.log('Fetching client details for ID:', clientId);
    axiosInstance.get(`api/client/${clientId}/`)
      .then(response => {
        console.log('Response data:', response.data);
        setClient(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch client details', error);
      });
  }, [clientId]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirmDelete = () => {
    axiosInstance.delete(`api/client/${clientId}/`)
      .then(response => {
        //toast.success('Client deleted successfully');
        navigate('/clientslist');
      })
      .catch(error => {
        //toast.error('Failed to delete client');
        console.error('Failed to delete client', error);
      });
  };

  if (!client) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  const handleEditClick = () => {
    navigate(`/clientsform/${clientId}`);
  };

  return (
    <div className='maestros-details'>

      <h2>Detalles del Cliente</h2>
      <hr />
      <div className='maestros-details-actions' style={{display: 'flex', flexDirection: 'row'}}>
        <h4>{client.client_name}</h4>

        <PermissionWrapper feature="cliente" action="update">
          <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
            onClick={handleEditClick}
          > 
            Editar
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
          </svg>
          </button>
        </PermissionWrapper>
        
        <PermissionWrapper feature="cliente" action="delete">
          <button className='btn btn-danger' onClick={handleShow}>
            Eliminar
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
          </button>
        </PermissionWrapper>

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Estas a punto de eliminar a {client.client_name} </h5>
          <p>¿Estás seguro de que lo deseas eliminar? Esta acción no podrá ser revertida</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
        </Modal>


      </div>

      <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className='first-col'>
        <p><strong>ID cliente:</strong> {client.client_id} </p>
        <p><strong>Tipo de documento:</strong> {client.document_type} </p>
        <p><strong>Documento:</strong> {client.document_id} </p>
        <p><strong>País:</strong> {client.country} </p>
        <p><strong>Ciudad:</strong> {client.city} </p>
        <p><strong>Dirección:</strong> {client.address} </p>
        <p><strong>Teléfono:</strong> {client.phone} </p>
        <p><strong>Página web:</strong> {client.web_page} </p>
        <p><strong>Correo envío de facturas:</strong> {client.email_billing} </p>
      </div>  
      <div className='second-col'>
      <p><strong>Prioridad:</strong> {getPriorityLabel(client.priority)} </p>
        <p><strong>Sector:</strong> {client.client_sector} </p>
        <p><strong>Estado:</strong> {client.status} </p>
        <p><strong>Cupo de credito (COP):</strong> {client.credit_limit} </p>
        <p><strong>Periodo de pago (días):</strong> {client.credit_time} </p>
        <p><strong>Revisión área comercial:</strong> {client.reviewed ? 'Revisado' : 'No revisado'} </p>
        <p><strong>Aprobado área financiera:</strong> {client.approved ? 'Aprobado' : 'No aprobado'} </p>
        <p><strong>Última vez contactado:</strong> {client.last_contacted} </p>
      </div>
      </div>

      <hr />
      <div className='maestros-contactos'>
        <h4>Contactos</h4>
        <p className='nombre-contactos'><strong>Nombre área comercial:</strong> {client.name_commercial_contact} </p>
        <p><strong>Correo:</strong> {client.email_commercial_contact} </p>
        <p><strong>Teléfono:</strong> {client.phone_commercial_contact} </p>

        <p className='nombre-contactos'><strong>Nombre área financiera:</strong> {client.name_financial_contact} </p>
        <p><strong>Correo:</strong> {client.email_financial_contact} </p>
        <p><strong>Teléfono:</strong> {client.phone_financial_contact} </p>

        <p className='nombre-contactos'><strong>Nombre área técnica:</strong> {client.name_technical_contact} </p>
        <p><strong>Correo:</strong> {client.email_technical_contact} </p>
        <p><strong>Teléfono:</strong> {client.phone_technical_contact} </p>
      </div>

    </div>
  )
}

export default ClientsDetails