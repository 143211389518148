import { useAuth } from '../context/AuthContext';
import rolesPermissions from '../context/Roles';

// Helper function to check if the user has the required permissions
const checkPermissions = (role, feature, action) => {
  return rolesPermissions[role]?.[feature]?.[action];
};

const PermissionWrapper = ({ feature, action, children }) => {
  const { auth } = useAuth();

  // Check if the user is authorized based on their role and permissions for the feature and action
  const isAuthorized = auth?.role && checkPermissions(auth.role, feature, action);

  return isAuthorized ? children : null;
};

export default PermissionWrapper;
