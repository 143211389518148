import React, {useState} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axiosPublic from '../api/Axios'
import '../styles/PasswordForget.css'

const schema = yup.object().shape({
    email: yup.string().email('Ingresa un correo válido').required('campo requerido')
})

function PasswordForget() {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const onSubmit = async (data) => {
        try {
            const response = await axiosPublic.post('api/user/password/reset',
                JSON.stringify({ email: data.email }),
            {
                headers: { 'Content-Type': 'application/json' },
            }
            );
            console.log("Correo enviado exitosamente!", response.data);
            setSuccess(true);
    
            reset();
            setTimeout(() => {
              navigate(`/signin`);
            }, 6000); 
    
        } catch (error) {
          console.error('Error submitting form:', error);
          setSuccess(false);
        }
      };    

  return (
    <div className='password-forget'>
        <section>
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-arrow-up" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4.5a.5.5 0 0 1-1 0V5.383l-7 4.2-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h5.5a.5.5 0 0 1 0 1H2a2 2 0 0 1-2-1.99zm1 7.105 4.708-2.897L1 5.383zM1 4v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1"/>
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.354 1.25 1.25a.5.5 0 0 1-.708.708L13 12.207V14a.5.5 0 0 1-1 0v-1.717l-.28.305a.5.5 0 0 1-.737-.676l1.149-1.25a.5.5 0 0 1 .722-.016"/>
            </svg>
        </div>
        <h2 style={{fontWeight: '700'}}>¿Olvidaste tu contraseña?</h2>
        <p>Ingresa el correo electrónico con el que estas registrado en tu cuenta, te enviaremos un correo para restablecer tu contraseña</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control 
                    type="email" 
                    placeholder="Ej: micorreo@correo.com" 
                    {...register('email')} 
                />
                <p style={{color: 'red'}}>{errors.email?.message}</p>
            </Form.Group>
            <Button 
                type="submit" 
                variant="primary"
                style={{width: '100%', backgroundColor:' #04706e', border: 'none'}}
            >
                Enviar
            </Button>
        </Form>
        {success && 
        <div style={{border: ' 1px solid green', margin:'10px 0px', padding: '5px'}}>
            <p style={{color: 'green', fontFamily: 'Outfit, sans serif', margin:'0'}}>Si una cuenta asociada a este correo electrónico existe, un mensaje será enviado!</p>  
        </div>
        }
        <Link to='/signin'>
            <p style={{color: '#014241', cursor: 'pointer'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
            </svg>
            Regresar al inicio de sesión
            </p>
        </Link>
    </section>
    </div>
  )
}

export default PasswordForget