import React, { createContext, useState, useContext} from 'react';
//import axiosInstance from '../api/Axios';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    const isAuthenticated = !!auth.access;

    return (
        <AuthContext.Provider value={{ auth, setAuth, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
