import React, { useState, useEffect, useMemo } from 'react';
import { Table, Pagination, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import ExcelProducts from './ExcelProducts';
import '../../appstyles/ClientsCSS/ClientsList.css';

const ProductsList = () => {
  const axiosInstance = useAxiosPrivate();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const rowsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts(currentPage, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  const fetchProducts = (page, searchQuery) => {
    const url = searchQuery
      ? `api/product/?page=${page}&size=${rowsPerPage}&product_name=${searchQuery}`
      : `api/product/?page=${page}&size=${rowsPerPage}`;

    axiosInstance.get(url)
      .then(response => {
        console.log('Fetched data:', response.data);
        setProducts(response.data);
        setTotalPages(response.data.totalPages); // Uncomment when totalPages is available
      })
      .catch(error => {
        console.log('Failed to fetch products', error);
      });
  };

  const filteredProducts = useMemo(() => {
    if (!products || !Array.isArray(products.data)) {
      console.error('products.data is not an array:', products);
      return [];
    }

    return products.data.filter(product => {
      return product.product_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  });
  }, [products, search]);

  const handleRowClick = (productId) => {
    navigate(`/products/details/${productId}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Create pagination items
  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    // Only show a few items before and after the current page
    if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      paginationItems.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    } else if (number === currentPage - 3 || number === currentPage + 3) {
      // Add ellipsis for hidden pages
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
    }
  }

  return (
    <div className='maestros-list'>
      <h2>Lista de Productos</h2>
      <hr />
      <Form inline style={{ width: '50%' }} onSubmit={(e) => e.preventDefault()}>
        <InputGroup>
          <Form.Control
            type="search"
            placeholder="Buscar por nombre de producto"            
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          >
          </Form.Control>
        </InputGroup>
      </Form>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre Producto</th>
            <th>Fabricante</th>
            <th>Categoría</th>
            <th>Subcategoría</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map(product => (
            <tr key={product.product_id} onClick={() => handleRowClick(product.product_id)} style={{ cursor: 'pointer' }}>
              <td>{product.product_id}</td>
              <td>{product.product_name}</td>
              <td>{product.manufacturer}</td>
              <td>{product.product_category}</td>
              <td>{product.product_subcategory}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ExcelProducts/>
      </Col>
      <Col>
      <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
      </Col>
    </Row> 
    </div>
  );
};

export default ProductsList;
