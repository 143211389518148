import React from 'react'
import '../appstyles/AppFooter.css'

function AppFooter() {
  return (
    <footer>
      <div className="footer-content">
        <p style={{margin: '10px'}}>
        Todos los derechos reservados &copy; 2024 nutritionmarket.net
        </p>
      </div>
    </footer>
  )
}

export default AppFooter