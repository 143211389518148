import React, { useState, useEffect, useMemo } from 'react';
import { Table, Pagination, Form, InputGroup, Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import ExcelClients from './ExcelClients';

import '../../appstyles/ClientsCSS/ClientsList.css';

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const axiosInstance = useAxiosPrivate();
  const [search, setSearch] = useState('');
  const rowsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  const fetchClients = (page, searchQuery) => {
    const url = searchQuery
      ? `api/client/?page=${page}&size=${rowsPerPage}&client_name=${searchQuery}`
      : `api/client/?page=${page}&size=${rowsPerPage}`;

      axiosInstance.get(url)
      .then(response => {
        console.log('Fetched data:', response.data);
        setClients(response.data);
        setTotalPages(response.data.totalPages); // Uncomment when totalPages is available
      })
      .catch(error => {
        console.log('Failed to fetch clients', error);
      });
  };

  const filteredClients = useMemo(() => {
    if (!clients || !Array.isArray(clients.data)) {
      console.error('clients.data is not an array:', clients);
      return [];
    }
    
    return clients.data.filter(client => {
      //console.log('Current client_name:', client.client_name);
      return client.client_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  });
  }, [clients, search]);

  const handleRowClick = (clientId) => {
    navigate(`/client/details/${clientId}`);
  };

   // Handle page change
   const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Create pagination items
  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    // Only show a few items before and after the current page
    if (number === 1 || number === totalPages || (number >= currentPage - 2 && number <= currentPage + 2)) {
      paginationItems.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    } else if (number === currentPage - 3 || number === currentPage + 3) {
      // Add ellipsis for hidden pages
      paginationItems.push(<Pagination.Ellipsis key={`ellipsis-${number}`} />);
    }
  } 

  return (
    <div className='maestros-list'>
    <h2>Lista de Clientes</h2>
    <hr />
    <Form inline style={{ width: '50%' }} onSubmit={(e) => e.preventDefault()}>
      <InputGroup>
        <Form.Control
          type="search"
          placeholder="Buscar por nombre de cliente"            
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        >
        </Form.Control>
      </InputGroup>
    </Form>
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Nombre Cliente</th>
          <th>Sector</th>
          <th>Teléfono</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        {filteredClients.map(client => (
          <tr key={client.client_id} onClick={() => handleRowClick(client.client_id)} style={{ cursor: 'pointer' }}>
            <td>{client.client_id}</td>
            <td>{client.client_name}</td>
            <td>{client.client_sector}</td>
            <td>{client.phone}</td>
            <td>{client.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Row>
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ExcelClients/>
      </Col>
      <Col>
      <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

        {paginationItems}

        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
      </Col>
    </Row>
    </div>
  );
};

export default ClientList;