import React, { useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, Row, InputGroup} from 'react-bootstrap';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import DropdownModal from '../../appcomponents/DropdownModal';
import '../../appstyles/ClientsCSS/ClientsForm.css'
import '../../appstyles/ProductsCSS/ProductsForm.css'

// Validación de campos
const requiredField = Yup.string().required('Este campo es requerido');

export const productSchema = Yup.object().shape({
  product_name: requiredField,
  manufacturer: requiredField,
  product_category: requiredField,
  product_subcategory: requiredField,
  product_presentation: requiredField,
  product_quality: requiredField,
  country: requiredField,
  tariff_code: Yup.string()
  .required('Este campo es requerido')
  .matches(/^\d+$/, 'Debe ser un número'),
  variable_tariff: Yup.number()
  .required('Este campo es requerido y debe ser un número')
  .typeError('Este campo es requerido y debe ser un número'),
  vat: Yup.number()
  .required('Este campo es requerido y debe ser un número')
  .typeError('Este campo es requerido y debe ser un número'),
});

function ProductsForm() {
  const axiosInstance = useAxiosPrivate();
  const { productId } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [options, setOptions] = useState({
      product_category: [],
      product_subcategory: [],
      product_presentation: [], 
      product_quality: []
  });

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
  resolver: yupResolver(productSchema),
  defaultValues: {
    product_category: '',
    product_subcategory: '',
    product_presentation: '',
    product_quality: ''
  }
  });

  // Fetch product data for editing
  const fetchProductData = async () => {
    try {
      const response = await axiosInstance.get(`api/product/${productId}/`);
      reset(response.data);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const endpoints = {
    product_category: 'api/product-category/',
    product_subcategory: 'api/product-subcategory/',
    product_presentation: 'api/product-presentation/',
    product_quality: 'api/product-quality/'
  };

  const fieldLabels = {
    product_category: 'Categoría',
    product_subcategory: 'Subcategoría',
    product_presentation: 'Presentación',
    product_quality: 'Calidad'
  };

  // Fetch options from backend
  const fetchOptions = async () => {
    try {
        const [categoryResponse, subcategoryResponse, presentationResponse, qualityResponse] = await Promise.all([
            axiosInstance.get(endpoints.product_category),
            axiosInstance.get(endpoints.product_subcategory),
            axiosInstance.get(endpoints.product_presentation),
            axiosInstance.get(endpoints.product_quality),
        ]);

        setOptions({
            product_category: categoryResponse.data,
            product_subcategory: subcategoryResponse.data,
            product_presentation: presentationResponse.data,
            product_quality: qualityResponse.data,
        });

        if (!productId) {
        setValue('product_category', categoryResponse.data[0]?.value || '');
        setValue('product_subcategory', subcategoryResponse.data[0]?.value || '');
        setValue('product_presentation', presentationResponse.data[0]?.value || '');
        setValue('product_quality', qualityResponse.data[0]?.value || '');
        }

    } catch (error) {
        console.error('Error fetching options:', error);
    }
  };

  // Use useEffect to fetch data when component mounts
  useEffect(() => {
    fetchOptions().then(() => {
      if (productId) {
        fetchProductData();
      }
    });
    // eslint-disable-next-line
  }, [setValue, productId, reset]);

  /*useEffect(() => {
    if (productId) {
    fetchProductData(productId);
    }
    fetchOptions();
      // eslint-disable-next-line
  }, [setValue, productId, reset]);*/


  const onSubmit = async (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined && value !== '')
    );
    console.log("Filtered Data:", filteredData);

    try{
      if (productId) {
        // Update existing product
        const response = await axiosInstance.put(`api/product/${productId}/`, filteredData); 
        console.log("Product updated successfully!", response.data);

        navigate(`/products/details/${productId}`)

      } else {
      // Create new product
      const response = await axiosInstance.post('api/product/', filteredData);
      console.log("Product created successfully!", response.data);

      reset();
      setTimeout(() => {
        navigate(`/productslist`);
      }, 1000);
      }
    }
    catch (error) {
      console.error('Error submitting form:', error);
    }
    };

  const handleShowModal = (field) => {
      console.log('Opening modal for field:', field); 
      setCurrentField(field);
      setModalShow(true);
  };

  const handleOptionCreated = (newOption) => {
      setOptions((prev) => ({
          ...prev,
          [currentField]: [...prev[currentField], newOption]
      }));
  };

  return (
    <div className='maestros-form'>
    <h2>{productId ? 'Editar Producto' : 'Crear Producto'}</h2> 
    <Form onSubmit={handleSubmit(onSubmit)}>
    <hr />
      <Row>
        {/* Primera Columna*/}
        <Col>
        
        <Form.Group controlId="product_name">
          <Form.Label>Nombre</Form.Label>
          <Form.Control type="text" {...register('product_name')} isInvalid={!!errors.product_name}/>
          <Form.Control.Feedback type="invalid">
                {errors.product_name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="manufacturer">
          <Form.Label>Fabricante</Form.Label>
          <Form.Control type="text" {...register('manufacturer')} isInvalid={!!errors.manufacturer} />
          <Form.Control.Feedback type="invalid">
                {errors.manufacturer?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="country">
          <Form.Label>País</Form.Label>
          <Form.Control type="text" {...register('country')} isInvalid={!!errors.country} />
          <Form.Control.Feedback type="invalid">
                {errors.country?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="product_category">
          <Form.Label>Categoría</Form.Label>
          <InputGroup>
          <Form.Select 
            id='product_category'
            aria-label="Default select example" {...register('product_category')} isInvalid={!!errors.product_category}>
              {options.product_category.map((opt) => (
              <option key={opt.id} value={opt.value}>{opt.value}</option>
              ))}
          </Form.Select>
          <Button variant="outline-primary" onClick={() => handleShowModal('product_category')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
          </svg>
          </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
                  {errors.product_category?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="product_subcategory">
          <Form.Label>Subcategoría</Form.Label>
          <InputGroup>
          <Form.Select 
            id='product_subcategory'
            aria-label="Default select example" {...register('product_subcategory')} isInvalid={!!errors.product_subcategory}>
              {options.product_subcategory.map((opt) => (
              <option key={opt.id} value={opt.value}>{opt.value}</option>
              ))}
          </Form.Select>
          <Button variant="outline-primary" onClick={() => handleShowModal('product_subcategory')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
          </svg>
          </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
                  {errors.product_subcategory?.message}
          </Form.Control.Feedback>
        </Form.Group>
        
        </Col>

        {/* Segunda Columna*/}
        <Col>

        <Form.Group controlId="product_presentation">
          <Form.Label>Presentación</Form.Label>
          <InputGroup>
          <Form.Select 
            aria-label="Default select example" {...register('product_presentation')} isInvalid={!!errors.product_presentation}>
              {options.product_presentation.map((opt) => (
              <option key={opt.id} value={opt.value}>{opt.value}</option>
              ))}
          </Form.Select>
          <Button variant="outline-primary" onClick={() => handleShowModal('product_presentation')} >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
          </svg>
          </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
                  {errors.product_presentation?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="product_quality">
          <Form.Label>Calidad</Form.Label>
          <InputGroup>
          <Form.Select 
            aria-label="Default select example" {...register('product_quality')} isInvalid={!!errors.product_quality}>
              {options.product_quality.map((opt) => (
              <option key={opt.id} value={opt.value}>{opt.value}</option>
              ))}
          </Form.Select>
          <Button variant="outline-primary" onClick={() => handleShowModal('product_quality')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
          </svg>
          </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
                  {errors.product_quality?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="tariff_code">
            <Form.Label>Posición arancelaria</Form.Label>
            <Form.Control type="text" {...register('tariff_code')} isInvalid={!!errors.tariff_code} />
            <Form.Control.Feedback type="invalid">
                  {errors.tariff_code?.message}
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="variable_tariff">
            <Form.Label>Arancel (%)</Form.Label>
            <Form.Control 
              type="number" 
              {...register('variable_tariff')} 
              isInvalid={!!errors.variable_tariff} 
              inputMode="decimal" 
              pattern="[0-9]*[.,]?[0-9]*" 
              step="any"
              onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null}
            />
            <Form.Control.Feedback type="invalid">
                  {errors.variable_tariff?.message}
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="vat">
            <Form.Label>IVA (%)</Form.Label>
            <Form.Control 
              type="number" 
              {...register('vat')} 
              isInvalid={!!errors.vat} 
              inputMode="decimal" 
              pattern="[0-9]*[.,]?[0-9]*" 
              step="any"
              onKeyDown={(e) => e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null}
            />
            <Form.Control.Feedback type="invalid">
                  {errors.vat?.message}
            </Form.Control.Feedback>
        </Form.Group> 
        </Col>
      </Row>
      <Button variant="primary" type="submit">
      {productId ? 'Guardar Cambios' : 'Crear Producto'}
      </Button>
    </Form>
    <DropdownModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        endpoint={endpoints[currentField]}
        onOptionCreated={handleOptionCreated}
        title={`Crear ${fieldLabels[currentField]}`}
    />
    </div>


  )
}

export default ProductsForm