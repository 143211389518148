import React from 'react'
import '../styles/Nosotros/TeamAbout.css'
/*images*/
import Mail from '@mui/icons-material/MailOutline';
import PhoneTalk from '@mui/icons-material/PhoneInTalkOutlined';


function TeamAbout() {
  return (
    <>
    <div className='conoce-nuestro-equipo'>
      <h3 style={{color: '#421831', fontFamily: 'Outfit, sans-serif', fontWeight: '700', fontStyle: 'normal'}}>CONOCE NUESTRO EQUIPO</h3>
    </div>
    <section className="aboutTeam">
      <div className='card-container'>
          <div className="aboutTeam-container">
              <h3>Maria Fernanda Pareja</h3>
          </div>
          <div className="aboutTeam-info">
              <h5>CEO y Fundadora</h5>
              <p><Mail style={{ fontSize: 20, marginRight: 5 }} />mariafpareja@nutrimarketchemicals.com</p>
              <p><PhoneTalk style={{ fontSize: 20, marginRight: 5}} />+57 317-5178521</p>
          </div>
      </div>
      <div className='card-container'>
          <div className="aboutTeam-container">
              <h3>Rodrigo Bouvet</h3>
          </div>
          <div className="aboutTeam-info">
              <h5>Gerente Compras Internacionales</h5>
              <p><Mail style={{ fontSize: 20, marginRight: 5 }} />comercial2@nutrimarketchemicals.com</p>
              <p><PhoneTalk style={{ fontSize: 20, marginRight: 5}} />+54 3447-447694</p>
          </div>
      </div>
      <div className='card-container'>
          <div className="aboutTeam-container">
              <h3>Daniela Echeverry</h3>
          </div>
          <div className="aboutTeam-info">
              <h5>Gerente Área Comercial</h5>
              <p><Mail style={{ fontSize: 20, marginRight: 5 }} />comercial@nutrimarketchemicals.com</p>
              <p><PhoneTalk style={{ fontSize: 20, marginRight: 5}} />+54 221-4779042</p>
          </div>
      </div>         
    </section>
    </>
  )
}

export default TeamAbout