import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
//import AuthContext from '../../context/AuthContext';
import { Spinner, Modal, Button } from 'react-bootstrap';
import '../../appstyles/SettingsCSS/UserDetails.css'

function UserDetails() {
  const axiosInstance = useAxiosPrivate();
  //const { auth } = useContext(AuthContext);
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const getPriorityLabel = (priority) => {
    switch (priority) {
        case 'ADMIN':
            return 'Administrador';
        case 'DEV':
            return 'Desarrollador';
        case 'CEO':
            return 'Director General';
        case 'DTCA':
          return 'Director de Calidad';
        case 'DTLO':
            return 'Director Logístico';
        case 'ANAD':
            return 'Analista Administrativo';
        case 'DTFI':
            return 'Director Financiero';
        case 'ANCO':
            return 'Analista de Compras';
        case 'DTCO':
            return 'Director Comercial';
        case 'ASCO':
            return 'Asesor Comercial';
        case 'AUBO':
            return 'Auxiliar de Bodega';
        case 'CLI':
            return 'Cliente';
        default:
            return 'Desconocido';
    }
  };

  useEffect(() => {
    console.log('Fetching user details for ID:', userId);
    axiosInstance.get(`api/user/${userId}/`)
      .then(response => {
        console.log('Response data:', response.data);
        setUser(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch user details', error);
      });
  }, [userId, axiosInstance]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirmDelete = () => {
    axiosInstance.delete(`api/user/${userId}/`)
      .then(response => {
        //toast.success('user deleted successfully');
        navigate('/settings');
      })
      .catch(error => {
        //toast.error('Failed to delete user');
        console.error('Failed to delete user', error);
      });
  };

  if (!user) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  const handleEditClick = () => {
    navigate(`/settings/userform/${userId}`);
  };

  return (
    <div className='user-details'>

        <h2>Detalles del Usuario</h2>
        <hr />

        <div className='profile' style={{color: '#602548', margin: '0 0 20px 0'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
        </svg>
        <div style={{width:'60%'}}>
            <h4>Nombre de Usuario</h4>
            <p style={{margin: '5px 0px', textTransform: 'capitalize'}}>{user.auth_user.username}</p>
        </div>
        
        <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
            onClick={handleEditClick}
            disabled={user.role === 'CLI'}
        > 
            Editar
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
        </svg>
        </button>

        <button className='btn btn-danger' onClick={handleShow}>
            Eliminar
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
        </svg>
        </button>

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Estas a punto de eliminar a <strong> {user.auth_user.username} </strong> </h5>
          <p>¿Estás seguro de que lo deseas eliminar? Esta acción no podrá ser revertida</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
        </Modal>
        </div>

        <h5>Información Personal</h5>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <div className='first-col'>
            <p><strong>ID Usuario:</strong> {user.user_id} </p>
            <p><strong>Nombre:</strong> {user.auth_user.first_name} </p>
            <p><strong>Apellido:</strong> {user.auth_user.last_name} </p>
            <p><strong>Correo:</strong> {user.email} </p>
        </div>  

        <div className='second-col'>
        <p><strong>Tipo de documento:</strong> {user.document_type=== 'OTHER' ? 'Otro' : user.document_type} </p>
            <p><strong>Documento:</strong> {user.document_id} </p>
            <p><strong>Cargo:</strong> {getPriorityLabel(user.role)} </p>
        </div>
        </div>

      <hr />

    </div>
  )
}

export default UserDetails