import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Form, Button } from 'react-bootstrap'
/*Images-Icons*/
import '../styles/Contacto/ContactUs.css'
import Contact from '../assets/vectores/contactus.svg'
import axiosPublic from '../api/Axios'

const schema = yup.object().shape({
  name: yup.string().required('Este campo es obligatorio'),
  email: yup.string().email('Correo electrónico no válido').required('Este campo es obligatorio'),
  phone: yup.string().required('Este campo es obligatorio').matches(/^\+?\d+$/, 'Telefono no es válido'),
  subject: yup.string().required('Este campo es obligatorio'),
  message: yup.string().required('Este campo es obligatorio')
})

function ContactUs() {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })
  
  const onSubmit = async(data) => {
    setLoading(true)
    try{
      const response = await axiosPublic.post('api/user/contact', data)
        setSuccess(true)
        reset()
    } catch(error){
      console.log("el correo no pudo ser enviado")
      setSuccess(false)
    } finally {
      setLoading(false)
    } 
  }

  const allFields = watch();
  const isFormFilled = allFields.name && allFields.email && allFields.message && allFields.phone && allFields.subject;

  return (
    <section className='contact-us'>
        <div className="title-container">
            <div className='txt-title'>
                <h2 style={{padding: '50px'}}>CONTACTANOS</h2>
            </div>
        </div>

        <section className='contact-row'>
          <div className='contact-image'>
            <img src={Contact} alt='contacto' className='contacto'/>
          </div>

        <div className='form-container' style={{display: 'flex', flexDirection: 'column'}}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId='name'>
              <Form.Control 
                type='text' 
                placeholder='Nombre' 
                {...register('name')}
              />
              </Form.Group>
              <Form.Group controlId='email'>
              <Form.Control 
                type='email' 
                placeholder='Email' 
                {...register('email')}
              />
              <p>{errors.email?.message}</p>
              </Form.Group>

              <Form.Group controlId='phone'>
              <Form.Control 
                type='text' 
                placeholder='Telefono' 
                {...register('phone')}
              />
              <p>{errors.phone?.message}</p>
              </Form.Group>

              <Form.Group controlId='subject'>
              <Form.Control 
                type='text' 
                placeholder='Asunto' 
                {...register('subject')}
              />
              </Form.Group>

              <Form.Group controlId='message'>
              <Form.Control 
                as="textarea" 
                rows={6} 
                placeholder='Mensaje' 
                {...register('message')}
              />
              </Form.Group>

              <Button 
                type='submit' 
                disabled={!isFormFilled || loading}
                style={{backgroundColor: '#025C5A', border: 'none', padding: '10px', width: '100%'}}
              >
                {loading ? 'Cargando...' : 'Enviar Mensaje'}
              </Button>
          </Form>

          {success && 
          <div style={{border: '1px solid green', padding: '10px', margin: '10px 0', width: '75%'}}>
            <p className="success" style={{margin: '0', color: 'green'}}>El correo ha sido enviado exitosamente.</p>
          </div>}
        </div>
        </section>
    </section>
  )
}

export default ContactUs