import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SelectedCategory from '../appcomponents/SelectedCategory';
import '../styles/Productos/Products.css';
/*images-icons*/
import products from '../assets/vectores/products.svg';
import LineaFarmaceutica from '../assets/imagenes/LineaFaramaceutica.png';
import LineaVeterinaria from '../assets/imagenes/LineaVeterinaria.png';
import NutricionAnimal from '../assets/imagenes/NutricionVeterinaria.png';
import HerbalesNutraceutica from '../assets/imagenes/HerbalesNutraceutica.png';
import Otros from '../assets/imagenes/otros.png';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';

function Products() {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleClick = (category) => {
    setSelectedCategory(category);
  };
  
  const handleBackClick = () => {
    setSelectedCategory(null);
  };

return (
    <section>
        <div className="title-container">
            <div className="txt-title">
                <h2>PRODUCTOS</h2>
            </div>
            <div className="services-img-container">
                <img src={products} alt="products" className="products" />
            </div>
        </div>
        {selectedCategory === null && (
            <>
                <div className="category-description">
                    <h3 style={{color:'#00504E', fontFamily:'Outfit, sans-serif;', fontWeight: '700'}}>Categorías</h3>
                    <p style={{fontFamily: 'Roboto, sans-serif', fontWeight: '400'}}>Selecciona una categoría para ver la lista de productos</p>
                </div>
            </>
        )}
        <div className="products-container">
            {selectedCategory === null && (
                <>
                    <div className="product" onClick={() => handleClick('Linea Farmaceutica')}>
                        <img src={LineaFarmaceutica} alt="LineaFarmaceutica" className="product-img" />
                        <h3>Linea Farmaceutica</h3>
                    </div>
                    <div className="product" onClick={() => handleClick('Linea Veterinaria')}>
                        <img src={LineaVeterinaria} alt="LineaVeterinaria" className="product-img" />
                        <h3>Linea Veterinaria</h3>
                    </div>
                    <div className="product" onClick={() => handleClick('Nutrición Animal')}>
                        <img src={NutricionAnimal} alt="NutricionAnimal" className="product-img" />
                        <h3>Nutrición Animal</h3>
                    </div>
                    <div className="product" onClick={() => handleClick('Herbales Nutracéutica')}>
                        <img src={HerbalesNutraceutica} alt="HerbalesNutraceutica" className="product-img" />
                        <h3>Herbales y Nutracéutica</h3>
                    </div>
                    <div className="product" onClick={() => handleClick('Otros')}>
                        <img src={Otros} alt="Otros" className="product-img" />
                        <h3>Otros</h3>
                    </div>
                </>
            )}
            {/* Render products based on the selected category */}
            {selectedCategory && (
                <div className='category-container'>
                    <Link className="volver" onClick={handleBackClick}> <KeyboardDoubleArrowLeft style={{fontSize:'30px'}}/> volver</Link>
                    <SelectedCategory category={selectedCategory} />
                </div>
            )}
        </div>
    </section>
);
}

export default Products;
