import React from 'react'
import { Link } from 'react-router-dom'
//Styles
import '../appstyles/Home.css'
import IconsHome from '../assets/imagenes/icons_home.png'

function Home() {
  return (
    <main className='home'>
      <section className='HomeContent' >
        <h3>Bienvenido al portal de gestión</h3>
        <p>En el menú lateral encontrarás las secciones y funciones disponibles para ti</p>
        <hr />
        <h3>Acciones Rápidas</h3>
        <section className="QuickActions">
          <div className="quick-actions">
            <Link to="/clientslist">
              <div className="quick-action">
                <h4>Clientes</h4>
              </div>
            </Link>
            <Link to="/productslist">
              <div className="quick-action">
                <h4>Productos</h4>
              </div>
            </Link>
            <Link to="/supplierslist">
              <div className="quick-action">
                <h4>Proveedores</h4>
              </div>
            </Link>
          </div>
          <div className='iconsHome'>
            <img src={IconsHome} alt="iconsHome"/>
          </div>
        </section>
      </section>
    </main>
  )
}

export default Home