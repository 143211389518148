import axios from 'axios';

const BASEURL = 'https://api.nutritionmarket.net/';

const axiosPublic = axios.create({
    baseURL: BASEURL,
});

export const axiosInstance = axios.create({
    baseURL: BASEURL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

export default axiosPublic;
