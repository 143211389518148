import React, { useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Form, Button, Col, Row } from 'react-bootstrap';
import '../../appstyles/ClientsCSS/ClientsForm.css'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../api/Axios';

// Validación de campos
const requiredField = Yup.string().required('Este campo es requerido');
const notRequiredField = Yup.number()
  .notRequired()
  .typeError('Debe ser un número')
  .transform((value, originalValue) => {
    return originalValue ? originalValue.trim() === '' ? null : value : null;
  });
const notRequiredString = Yup.string().notRequired().nullable(); 

export const supplierSchema = Yup.object().shape({
  supplier_name: requiredField,
  document_type: requiredField,
  document_id: Yup.number()
    .required('Este campo es requerido')
    .typeError('Debe ser un número'),
  country: requiredField,
  city: requiredField,
  address: requiredField,
  phone: Yup.string()
    .required('Este campo es requerido')
    .matches(/^\+?\d+$/, 'Telefono no es válido'),
  web_page: notRequiredString,
  category: requiredField,
  is_financer: Yup.boolean(),
  status: requiredField,
  reviwed: Yup.boolean(),
  approved: Yup.boolean(),
  last_contacted: Yup.date().nullable(),
  // Contactos
  name_commercial_contact: requiredField,
  email_commercial_contact: Yup.string()
    .required('Este campo es requerido')
    .email('Correo no es válido'),
  phone_commercial_contact: Yup.string()
    .required('Este campo es requerido')
    .matches(/^\+?\d+$/, 'Telefono no es válido'),
  name_financial_contact: notRequiredString,
  email_financial_contact: Yup.string()
    .nullable()
    .email('Correo no es válido'),
  phone_financial_contact: Yup.string()
    .notRequired()
    .test(
      'is-valid-phone',
      'Telefono no es válido',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  name_technical_contact: notRequiredString,
  email_technical_contact: Yup.string()
    .notRequired()
    .email('Correo no es válido'),
  phone_technical_contact: Yup.string()
    .notRequired()
    .test(
      'is-valid-phone',
      'Telefono no es válido',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  // Información bancaria
  bank_account: notRequiredString,
  branch_office: notRequiredString,
  account_number: Yup.string()
    .notRequired()
    .test(
      'is-valid',
      'Debe ser un número',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  account_type: notRequiredString,
  swift_code: Yup.string()
    .notRequired()
    .test(
      'is-valid',
      'Debe ser un número',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  iban: Yup.string()
    .notRequired()
    .test(
      'is-valid',
      'Debe ser un número',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  aba: Yup.string()
    .notRequired()
    .test(
      'is-valid',
      'Debe ser un número',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  inter_bank: notRequiredString,
  inter_account_number: Yup.string()
    .notRequired()
    .test(
      'is-valid',
      'Debe ser un número',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  inter_swift_code: Yup.string()
    .notRequired()
    .test(
      'is-valid',
      'Debe ser un número',
      (value) => !value || /^\+?\d+$/.test(value)
    ),
  // Información financiera
  date_last_tax_year: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue && originalValue.trim() === '' ? null : value))
    .typeError('Debe ser una fecha'),
  income: notRequiredField,
  expenses: notRequiredField,
  active_income: notRequiredField,
  passive_income: notRequiredField,
  patrimony: notRequiredField,
  net_profit: notRequiredField,
  // Gestión de calidad
  qms_type: notRequiredString,
  qms_name: notRequiredString,
});

function SuppliersForm() {
  const axiosInstance = useAxiosPrivate();
  const { supplierId } = useParams();
  const navigate = useNavigate();
  
  const defaultValues = {
    document_type: 'NIT',
    category: 'Fabricante',
    status: 'Oportunidad de negocio', 
    reviewed: false,
    approved: false,
    is_financer: false,
    date_last_tax_year: null,
    account_type: '',
    qms_type: '',
  };

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
  resolver: yupResolver(supplierSchema),
  defaultValues
  });

  useEffect(() => {
    if (supplierId) {
      // Fetch supplier data for editing
      const fetchSuppliertData = async () => {
        try {
          const response = await axiosInstance.get(`api/supplier/${supplierId}/`);
          reset(response.data);
        } catch (error) {
          console.error('Error fetching supplier data:', error);
        }
      };

      fetchSuppliertData();
    }
  }, [supplierId, reset]);

  const onSubmit = async (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined && value !== '')
    );
    console.log("Filtered Data:", filteredData);
    
    try {
      let response;
      if (supplierId) {
        // Update existing supplier
        response = await axiosInstance.put(`api/supplier/${supplierId}/`, filteredData); 
        console.log("Supplier updated successfully!", response.data);

        navigate(`/supplier/details/${supplierId}`);

      } else {
        // Create new supplier
        response = await axiosInstance.post('api/supplier/', filteredData);
        console.log("Supplier created successfully!", response.data);

        reset();
        setTimeout(() => {
          navigate(`/supplierslist`);
        }, 1000);
      } 

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Función para enviar los datos del formulario 
  return (
    <div className='maestros-form'>
    <h2>{supplierId ? 'Editar Proveedor' : 'Crear Proveedor'}</h2> 
    <Form onSubmit={handleSubmit(onSubmit)}>
    <hr />
    <h5>Información General</h5>
      <Row>
        {/* Primera Columna*/}
        <Col>

        <Form.Group controlId="supplier_name">
          <Form.Label>Razón social</Form.Label>
          <Form.Control type="text" {...register('supplier_name')} isInvalid={!!errors.supplier_name}/>
          <Form.Control.Feedback type="invalid">
                {errors.supplier_name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="document_type">
          <Form.Label>Tipo de documento</Form.Label>
          <Form.Select aria-label="Default select example" {...register('document_type')} isInvalid={!!errors.document_type}>
              <option value="NIT">NIT</option>
              <option value="CC">Cedula de Ciudadania (CC)</option>
              <option value="TAX ID">Tax ID</option>
              <option value="Otro">Otro</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                  {errors.document_type?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="document_id">
          <Form.Label>Documento</Form.Label>
          <Form.Control type="text" {...register('document_id')} isInvalid={!!errors.document_id} />
          <Form.Control.Feedback type="invalid">
                {errors.document_id?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="country">
            <Form.Label>País</Form.Label>
            <Form.Control type="text" {...register('country')} isInvalid={!!errors.country} />
            <Form.Control.Feedback type="invalid">
                  {errors.country?.message}
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="city">
          <Form.Label>Ciudad</Form.Label>
          <Form.Control type="text" {...register('city')} isInvalid={!!errors.city} />
          <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="address">
          <Form.Label>Dirección</Form.Label>
          <Form.Control type="text" {...register('address')} isInvalid={!!errors.address}/>
          <Form.Control.Feedback type="invalid">
                  {errors.address?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="phone">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control type="text" {...register('phone')} isInvalid={!!errors.phone}/>
          <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="web_page">
          <Form.Label>Página web (opcional)</Form.Label>
          <Form.Control type="text" {...register('web_page')} isInvalid={!!errors.web_page}/>
          <Form.Control.Feedback type="invalid">
                  {errors.web_page?.message}
          </Form.Control.Feedback>
        </Form.Group>

        </Col>
        {/* Segunda Columna*/}
        <Col>

        <Form.Group controlId="category">
          <Form.Label>Categoría del proveedor</Form.Label>
          <Form.Select aria-label="Default select example" {...register('category')} isInvalid={!!errors.category}>
              <option value="Fabricante">Fabricante</option>
              <option value="Trading">Trading</option>
              <option value="Representante">Representante</option>
              <option value="Especificado">No especificado</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                  {errors.category?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="status">
          <Form.Label>Estado del proveedor</Form.Label>
          <Form.Select aria-label="Default select example" {...register('status')} isInvalid={!!errors.status}>
              <option value="Frecuente">Frecuente</option>
              <option value="Ocasional">Ocasional</option>
              <option value="Oportunidad de negocio">Oportunidad de negocio</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
                  {errors.status?.message}
          </Form.Control.Feedback>
        </Form.Group>

        {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Group controlId="is_financer">
          <Form.Check
            inline
            label="Es financiador"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
            {...register('is_financer')}
          />
          </Form.Group>
          
          <Form.Group controlId="reviewed">
          <Form.Check
            inline
            label="Revisión por área comercial"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
            {...register('reviewed')}
          />
          </Form.Group>

          <Form.Group controlId="approved">
          <Form.Check
            inline
            label="Aprobado por área financiera"
            name="group1"
            type={type}
            id={`inline-${type}-3`}
            {...register('approved')}
          />
          </Form.Group>
 
          <Form.Group controlId="last_contacted">
            <Form.Label>Última vez contactado</Form.Label>
            <Form.Control placeholder="Disabled input" disabled={!supplierId}  type="date" {...register('last_contacted')} isInvalid={!!errors.last_contacted} />
            <Form.Control.Feedback type="invalid">
                    {errors.last_contacted?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        ))}</Col>
      </Row>
    <hr />
    <h5>Contactos</h5>
    <Row>
      <Col>

      <Form.Group controlId="name_commercial_contact">
        <Form.Label>Nombre área comercial*</Form.Label>
        <Form.Control type="text" {...register('name_commercial_contact')} isInvalid={!!errors.name_commercial_contact} />
        <Form.Control.Feedback type="invalid">
          {errors.name_commercial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>
      
      <Form.Group controlId="email_commercial_contact">
        <Form.Label>Correo electrónico*</Form.Label>
        <Form.Control type="email" {...register('email_commercial_contact')} isInvalid={!!errors.email_commercial_contact}/>
        <Form.Control.Feedback type="invalid">
          {errors.email_commercial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>  

      <Form.Group controlId="phone_commercial_contact">
        <Form.Label>Teléfono*</Form.Label>
        <Form.Control type="text" {...register('phone_commercial_contact')} isInvalid={!!errors.phone_commercial_contact} />
        <Form.Control.Feedback type="invalid">
          {errors.phone_commercial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>
    
      </Col>

      <Col>
      <Form.Group controlId="name_financial_contact" >
        <Form.Label>Nombre área financiera</Form.Label>
        <Form.Control type="text" {...register('name_financial_contact')}/>
      </Form.Group>

      <Form.Group controlId="email_financial_contact">
        <Form.Label>Correo electrónico</Form.Label>
        <Form.Control type="email" {...register('email_financial_contact')} isInvalid={!!errors.email_financial_contact}/>
        <Form.Control.Feedback type="invalid">
          {errors.email_financial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="phone_financial_contact" >
        <Form.Label>Teléfono</Form.Label>
        <Form.Control type="text" {...register('phone_financial_contact')} isInvalid={!!errors.phone_financial_contact} />
        <Form.Control.Feedback type="invalid">
          {errors.phone_financial_contact?.message}
        </Form.Control.Feedback>
      </Form.Group> 
      
      </Col>

      <Col>

      <Form.Group controlId='name_technical_contact'>
        <Form.Label>Nombre área técnica</Form.Label>
        <Form.Control type="text" {...register('name_technical_contact')}/>
      </Form.Group>

      <Form.Group controlId="email_technical_contact">
        <Form.Label>Correo electrónico</Form.Label>
        <Form.Control type="email" {...register('email_technical_contact')} isInvalid={!!errors.email_technical_contact}/>
        <Form.Control.Feedback type="invalid">
            {errors.email_technical_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId='phone_technical_contact'>
        <Form.Label>Teléfono</Form.Label>
        <Form.Control type="text" {...register('phone_technical_contact')} isInvalid={!!errors.phone_technical_contact} />
        <Form.Control.Feedback type="invalid">
            {errors.phone_technical_contact?.message}
        </Form.Control.Feedback>
      </Form.Group>

      </Col>  
    </Row>
    <hr />
    <h5>Información Bancaria (opcional)</h5>
    <Row>
      <Col>

      <Form.Group controlId="bank_account">
        <Form.Label>Nombre del banco</Form.Label>
        <Form.Control type="text" {...register('bank_account')} isInvalid={!!errors.bank_account} />
        <Form.Control.Feedback type="invalid">
          {errors.bank_account?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="branch_office">
        <Form.Label>Sucursal</Form.Label>
        <Form.Control type="text" {...register('branch_office')} isInvalid={!!errors.branch_office} />
        <Form.Control.Feedback type="invalid">
          {errors.branch_office?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="account_number">
        <Form.Label>Número de cuenta</Form.Label>
        <Form.Control type="text" {...register('account_number')} isInvalid={!!errors.account_number} />
        <Form.Control.Feedback type="invalid">
          {errors.account_number?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="account_type">
        <Form.Label>Tipo de cuenta</Form.Label>
        <Form.Select aria-label="Default select example" {...register('account_type')} isInvalid={!!errors.account_type}>
              <option value="Corriente">Corriente</option>
              <option value="Ahorros">Ahorros</option>
              <option value="Credito">Credito</option>
              <option value="Otro">Otro</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
                  {errors.account_type?.message}
          </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="swift_code">
        <Form.Label>Número SWIFT</Form.Label>
        <Form.Control type="text" {...register('swift_code')} isInvalid={!!errors.swift_code} />
        <Form.Control.Feedback type="invalid">
          {errors.swift_code?.message}
        </Form.Control.Feedback>
      </Form.Group>
      </Col>

      <Col>

      <Form.Group controlId="iban">
        <Form.Label>IBAN</Form.Label>
        <Form.Control type="text" {...register('iban')} isInvalid={!!errors.iban} />
        <Form.Control.Feedback type="invalid">
          {errors.iban?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="aba">
        <Form.Label>ABA</Form.Label>
        <Form.Control type="text" {...register('aba')} isInvalid={!!errors.aba} />
        <Form.Control.Feedback type="invalid">
          {errors.aba?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="inter_bank">
        <Form.Label>Nombre banco intermediario</Form.Label>
        <Form.Control type="text" {...register('inter_bank')} isInvalid={!!errors.inter_bank} />
        <Form.Control.Feedback type="invalid">
          {errors.aba?.message}
        </Form.Control.Feedback>
      </Form.Group>



      <Form.Group controlId="inter_account_number">
        <Form.Label>Número de cuenta intermediario</Form.Label>
        <Form.Control type="text" {...register('inter_account_number')} isInvalid={!!errors.inter_account_number} />
        <Form.Control.Feedback type="invalid">
          {errors.inter_account_number?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="inter_swift_code">
        <Form.Label>Código SWIFT intermediario</Form.Label>
        <Form.Control type="text" {...register('inter_swift_code')} isInvalid={!!errors.inter_swift_code} />
        <Form.Control.Feedback type="invalid">
          {errors.inter_swift_code?.message}
        </Form.Control.Feedback>
      </Form.Group>

      </Col>
    </Row>
    <hr />
    <h5>Información Financiera (opcional)</h5>
    <Row>
      <Col>

      <Form.Group controlId="date_last_tax_year">
        <Form.Label>Fecha del último corte fiscal</Form.Label>
        <Form.Control type="date" {...register('date_last_tax_year')} isInvalid={!!errors.date_last_tax_year} />
        <Form.Control.Feedback type="invalid">
          {errors.date_last_tax_year?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="income">
        <Form.Label>Ingresos (USD) </Form.Label>
        <Form.Control type="number" {...register('income')} isInvalid={!!errors.income} />
        <Form.Control.Feedback type="invalid">
          {errors.income?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="expenses">
        <Form.Label>Egresos (USD)</Form.Label>
        <Form.Control type="number" {...register('expenses')} isInvalid={!!errors.expenses} />
        <Form.Control.Feedback type="invalid">
          {errors.expenses?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="active_income">
        <Form.Label>Activos (USD)</Form.Label>
        <Form.Control type="number" {...register('active_income')} isInvalid={!!errors.active_income} />
        <Form.Control.Feedback type="invalid">
          {errors.active_income?.message}
        </Form.Control.Feedback>
      </Form.Group>

      </Col>

      <Col>

      <Form.Group controlId="passive_income">
        <Form.Label>Pasivos (USD)</Form.Label>
        <Form.Control type="number" {...register('passive_income')} isInvalid={!!errors.passive_income} />
        <Form.Control.Feedback type="invalid">
          {errors.passive_income?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="patrimony">
        <Form.Label>Patrimonio (USD)</Form.Label>
        <Form.Control type="number" {...register('patrimony')} isInvalid={!!errors.patrimony} />
        <Form.Control.Feedback type="invalid">
          {errors.patrimony?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="net_profit">
        <Form.Label>Ganancias netas (USD)</Form.Label>
        <Form.Control type="number" {...register('net_profit')} isInvalid={!!errors.net_profit} />
        <Form.Control.Feedback type="invalid">
          {errors.net_profit?.message}
        </Form.Control.Feedback>
      </Form.Group>

      </Col>
    </Row>
    <hr />
    <h5>Sistema de gestión de calidad o certificaciones (opcional)</h5>
    <Row style={{width: '50%'}}>
      <Col>

      <Form.Group controlId="qms_type">
        <Form.Label>Tipo de sistema de gestión de calidad</Form.Label>
        <Form.Select aria-label="Default select example" {...register('qms_type')} isInvalid={!!errors.qms_type}>
              <option value="Sistema">Sistema</option>
              <option value="Certificación">Certificación</option>
              <option value="Resolución">Resolución</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.qms_type?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="qms_name">
        <Form.Label>Nombre del sistema de gestión de calidad</Form.Label>
        <Form.Control type="text" {...register('qms_name')} isInvalid={!!errors.qms_name} />
        <Form.Control.Feedback type="invalid">
          {errors.qms_name?.message}
        </Form.Control.Feedback>
      </Form.Group>

      </Col>
    </Row>
    <Button variant="primary" type="submit">
      {supplierId ? 'Guardar Cambios' : 'Crear Proveedor'}
    </Button>
    </Form>
    </div> 
  )
}

export default SuppliersForm