import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import AuthContext from '../context/AuthContext';
import axios from '../api/Axios';
import logo from "../assets/logo/ncm_logo.png"
import '../styles/Sesion.css';

const LOGIN_URL = 'api/login/';

function Sesion() {
  const { setAuth } = useContext(AuthContext);

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"

  const userRef = useRef();
  const errRef = useRef()

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [username, password])


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(LOGIN_URL,
          JSON.stringify({ username, password }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );

      const access = response?.data?.access;
      const role = response?.data?.role;
      setAuth({ username, password, access, role });

      setUsername('');
      setPassword('');

      navigate(from, { replace: true })
    } catch (err) {
      if (!err?.response) {
          setErrMsg('No hay conexión con el servidor');
      } else if (err.response?.status === 400) {
          setErrMsg('Completar los campos');
      } else if (err.response?.status === 401) {
          setErrMsg('El usuario o la contraseña son incorrectos');
      } else {
          setErrMsg('Inicio de sesión fallido');
      }
      errRef.current.focus();
    } finally {
      setLoading(false); 
    }
  }
  
  return (
    
    <section className='inicio-sesion'>
        <section className="signin-gradient mobile-hide">
        <h5>Descubre el futuro de tus compras y potencia tu negocio con nuestras 
            soluciones innovadoras.</h5>
        </section>
        <form className="signin-container signin-mobile" onSubmit={handleSubmit}>
          <div className='logo-signin'>
            <img src={logo} alt="logo" className='logo'/>
          </div>
          <h4>Inicio de Sesión</h4>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
            style={{
              margin: '0',
              padding: '10px 15px',
              color: 'red', 
              border: '1px solid red', 
              display: errMsg ? 'block' : 'none',
              fontSize: '15px',
            }}
          >
            {errMsg}
          </p>
          <div className='email-container'>
            <input 
              type="text" 
              id='username'
              ref={userRef}
              placeholder="Usuario" 
              className='username'
              autoComplete="false"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
            />
          </div>
          <div className='password-container'>
            <input 
              type="password" 
              placeholder="Contraseña" 
              className='password'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>
          <button className="btn-login" disabled={!username || !password || loading}>
            {loading ? 'Cargando...' : 'Ingresar'}
          </button>
          <div className='remember-forgot'>
            <Link to="/signin/forgetpassword" className='forgot-password'>Olvidé mi contraseña</Link>
          </div>  
        </form>
    </section>
  );
}

export default Sesion;