import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from '../api/Axios';

function DropdownModal({ show, onHide, endpoint, onOptionCreated, title }) {
    const [optionName, setOptionName] = useState('');

    const handleSave = async () => {
        try {
            const response = await axiosInstance.post(endpoint, { value: optionName });
            onOptionCreated(response.data);  // Pass the new option back to the parent
            setOptionName('');
            onHide();
        } catch (error) {
            console.error('Error creating option:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="value">
                    <Form.Label>Escriba la opción que desea agregar:</Form.Label>
                    <Form.Control
                        type="text"
                        value={optionName}
                        onChange={(e) => setOptionName(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="primary" style={{backgroundColor: '#025C5A', borderColor:'#025C5A' }} onClick={handleSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DropdownModal;
