import React from 'react'
import '../styles/Servicios/ClientsServices.css'
//Images-Icons
import farmaceutica from '../assets/iconos/farmaceutica.png'
import API from '../assets/iconos/API.png'
import animal from '../assets/iconos/animal.png'
import alimentacion from '../assets/iconos/alimentacion.png'
import veterinaria from '../assets/iconos/veterinaria.png'

function ClientsServices() {
  return (
    <section>
      <h3 className='clients'>CLIENTES</h3>
      <div className='clients-container'>
        <div className='clients-degraded'>
          <p>
          <b>Como empresa de carácter privado que promueve los negocios nacionales 
          e internacionales ofrecemos los siguientes servicios a nuestros clientes:</b>
          </p>
          <ol>
            <li>Suministro de productos de alta calidad con procesos logísticos rápidos 
              y eficientes.</li>
            <li>Asesoramiento en homologación de nuevos fabricantes y contacto con 
              múltiples proveedores.</li>
          </ol>
        </div>
      </div>
      <h4 className='business'>Lineas de Negocio</h4>
      <div className='clients-icons-container'>
        <div className='clients-icons'>
          <img src={farmaceutica} alt="clientsIcons" className='clientsIcons'/>
          <p>
          Farmacéutica
          </p>
        </div>
        <div className='clients-icons'>
          <img src={API} alt="clientsIcons" className='clientsIcons'/>
          <p>
          Principios <br/> Activos
          </p>
        </div>
        <div className='clients-icons'>
          <img src={animal} alt="clientsIcons" className='clientsIcons'/>
          <p>
          Nutrición <br/> Animal
          </p>
        </div>
        <div className='clients-icons'>
          <img src={alimentacion} alt="clientsIcons" className='clientsIcons'/>
          <p>
          Alimentación <br/> Humana
          </p>
        </div>
        <div className='clients-icons'>
          <img src={veterinaria} alt="clientsIcons" className='clientsIcons'/>
          <p>
          Veterinaria
          </p>
        </div>
      </div>
    </section>
  )
}

export default ClientsServices