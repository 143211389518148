import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../context/AuthContext';
import '../appstyles/AppHeader.css'
//logo
import Logo from '../assets/logo/horizontal_logo.png'

function AppHeader() {
  const { isAuthenticated } = useAuth();

  return (
    <header>
      <div className="logo">
        <Link to={isAuthenticated ? '/home' : '/'}>
          <img src={Logo} alt="nutrimarket logo" className='Appheader-logo'/>
        </Link>
      </div>
    </header>
  )
}

export default AppHeader