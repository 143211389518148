import React from 'react'
import SupliersServices from '../components/SupliersServices'
import ClientsServices from '../components/ClientsServices'
import '../styles/Servicios/Services.css'
//Images-Icons
import services from "../assets/vectores/services.svg"


function Services() {
  return (
    <>
    <div className="title-container">
            <div className='txt-title'>
                <h2>SERVICIOS</h2>
            </div>
            <div className='services-img-container'>
                <img src={services} alt="services" className='services'/>
            </div>
    </div>
    <ClientsServices />
    <SupliersServices />
    </>
  )
}

export default Services