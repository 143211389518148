import React, { useState } from 'react'
import Logo from '../assets/logo/horizontal_logo.png'
import { Link } from "react-router-dom"
import '../styles/Header.css'
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header>
      <div className="header-top">
        <div className="logo">
            <Link to="/">
                <img src={Logo} alt="nutrimarket logo" className='header-logo'/>
            </Link>
        </div>
        <div className='menu' onClick={() => setMenuOpen(!menuOpen)}>
          <MenuIcon style={{color: '#421831', fontSize: '45px'}}/>
        </div>
      </div>
        <nav className={`navbar ${menuOpen ? "open" : "close"}`}>
            <Link to="/nosotros">Nosotros</Link>
            <Link to="/productos">Productos</Link>
            <Link to="/servicios">Servicios</Link>
            <Link to="/contacto">Contacto</Link>
            <Link to="/signin" className='sign-in'>
              <button className="signin-button">Ingresar</button>
            </Link>
        </nav>
    </header>
  )
}

export default Header