import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from './hooks/useRefreshToken';
import { useAuth } from './AuthContext';
import { Spinner } from 'react-bootstrap';

const PersistentLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }
        !auth?.access ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [])

    return (
        <>
            {isLoading 
            ? 
            <div
            style={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                }   
            }
            >
                <p 
                    style={{
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: '700',
                        fontSize: '1.5rem',
                        marginBottom: '1rem',
                    }
                }
                >
                    Cargando...
                </p>
                <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem', color: '#602548'}} />
            </div> 
            : 
            <Outlet />}
        </>
    )
}

export default PersistentLogin;