import React from 'react'
/*Images-Icons*/
import argentina from "../assets/iconos/AR.png"
import china from "../assets/iconos/CH.png"
import colombia from "../assets/iconos/CO.png"
import us from "../assets/iconos/US.png"
import india from "../assets/iconos/IN.png"
/*Styles*/
import '../styles/Nosotros/OfficesAbout.css'

function OfficesAbout() {
  return (
    <section className="aboutOffice">
        <div className='flag-container nuestras-oficinas'>
            <h2 className='nuestras-oficinas' style={{fontFamily:'Outfit, sans-serif', fontWeight:'700', color: '#017C79', margin: '0px'}}>NUESTRAS <br/> OFICINAS</h2>
        </div>
        <div className='flag-container'>
            <div className='flag-container-img'>
                <img src={colombia} alt="colombia" className='flag'/>
            </div>
            <div className='flag-container-txt'>
                <h4>Colombia</h4>
                <p>Oficina central para atender clientes de Colombia y países vecinos, 
                con equipo comercial, logístico y de soporte técnico.</p>
            </div>
        </div>
        <div className='flag-container'>
            <div className='flag-container-img'>
                <img src={argentina} alt="argentina" className='flag'/>
            </div>
            <div className='flag-container-txt'>
                <h4>Argentina</h4>
                <p>Contamos con equipo de compras y soporte comercial</p>
            </div>
        </div>
        <div className='flag-container'>
            <div className='flag-container-img'>
                <img src={us} alt="US" className='flag'/>
            </div>
            <div className='flag-container-txt'>
                <h4>Estados Unidos</h4>
                <p>Conectamos clientes de América del Sur con Asia, desarrollamos suministros 
                en EE.UU. y brindamos acuerdos de seguros y fletes.</p>
            </div>
        </div>
        <div className='flag-container'>
            <div className='flag-container-img'>
                <img src={china} alt="china" className='flag'/>
            </div>
            <div className='flag-container-txt'>
                <h4>China</h4>
                <p>Nuestro socio en China brinda soporte técnico, logístico y 
                gestiones de auditoría</p>
            </div>
        </div>
        <div className='flag-container'>
            <div className='flag-container-img'>
                <img src={india} alt="india" className='flag'/>
            </div>
            <div className='flag-container-txt'>
                <h4>India</h4>
                <p>Presencia local que facilita la comunicación con fabricantes aliados y servicios 
                de logística y auditoría.</p>
        </div>
        </div>
    </section>
  )
}

export default OfficesAbout