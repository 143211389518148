import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosPublic from '../api/Axios'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import '../styles/PasswordReset.css';

const schema = yup.object().shape({
    new_password: yup.string().required('campo requerido'),
    confirm_password: yup.string().required('campo requerido'),
});

const PasswordReset = () => {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
 
    // Extract the token from the URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const onSubmit = async (data) => {
        const { new_password, confirm_password } = data;

        if (new_password !== confirm_password) {
            setError('Los campos no son iguales');
            return;
        }

        try {
            const response = await axiosPublic.post('api/user/password/confirm', {
                token,
                new_password,
                confirm_password,
            });
            
            if (response.data.success) {
                setSuccess('La contraseña ha sido actualizada correctamente!');
                // Redirect to login page
                setTimeout(() => {
                    navigate('/signin');
                }, 3000);
            } else {
                setError('Fallo la actualización de la contraseña.');
            }
        } catch (err) {
            setError('Hubo un error en el cambio de contraseña. Por favor intente más tarde!');
        }
    };

    return (
        <div className='password-reset'>

            <section>
            <h2>Cambia tu contraseña</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='new_password'>
                <Form.Label>Nueva Contraseña</Form.Label>
                <Form.Control
                    type="password" 
                    {...register('new_password')}
                    isInvalid={!!errors.new_password}
                    required
                />
                <p style={{color: 'red'}}>{errors.new_password?.message}</p>
                </Form.Group>

                <Form.Group controlId='confirm_password'>
                <Form.Label>Confirmar Contraseña</Form.Label>
                <Form.Control
                    type="password"                        
                    {...register('confirm_password')}
                    isInvalid={!!errors.confirm_password}
                    required
                />
                <p style={{color: 'red'}}>{errors.confirm_password?.message}</p>
                </Form.Group>

                {error && 
                <div style={{border: '1px solid red', padding: '10px', margin: '10px 0'}}>
                    <p className="error" style={{margin: '0', color: 'red'}}>{error}</p>
                </div>}
                {success && 
                <div style={{border: '1px solid green', padding: '10px', margin: '10px 0'}}>
                    <p className="success" style={{margin: '0', color: 'green'}}>{success}</p>
                </div>
                }

                <Button
                    type="submit" 
                    variant="primary"
                    style={{width: '100%', backgroundColor:' #04706e', border: 'none', padding: '10px'}} 
                >
                    Actualizar Contraseña
                </Button>
            </Form>
            </section>

        </div>
    );
};

export default PasswordReset;
