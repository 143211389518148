import { axiosInstance } from "../../api/Axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { useAuth } from "../AuthContext";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        const requestInterceptor = axiosInstance.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.access}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );
        
        const responseInterceptor = axiosInstance.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const accessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                    return axiosInstance(prevRequest);
                }
                if (error?.response?.status === 400 && error?.response?.data?.detail === "Token is invalid or expired") {
                    window.location.reload();
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };

    }, [auth, refresh]);

    return axiosInstance;
}

export default useAxiosPrivate;
