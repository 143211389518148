import React from 'react';

const SelectedCategory = ({ category }) => {
  // This component should render the products based on the selected category
  // You can fetch the product data from a JSON file or an API and map over it to render each product
  return (
    <div className="selected-category">
      <h2>{category}</h2>
      <ul>
        <li>Product 1</li>
        <li>Product 2</li>
        <li>Product 3</li>
      </ul>
    </div>
  );
};

export default SelectedCategory;
