import React from 'react'
import '../styles/Servicios/SupliersServices.css'
//Images-Icons
import mainIcons from '../assets/imagenes/icons_home.png'
import mainIconsMobile from '../assets/imagenes/ProvedoresMobile.png'
import estadisticas from '../assets/iconos/estadisticas.png'
import mercado from '../assets/iconos/mercado.png'
import documento from '../assets/iconos/documentos.png'
import seguro from '../assets/iconos/seguro.png'
import financiacion from '../assets/iconos/finanzas.png'

function SupliersServices() {
  return (
    <section>
      <h3 className='prov'>PROVEEDORES</h3>
      <div className='suppliers-row'>
        <div className='mainIcon-container'>
          <img src={mainIcons} alt="mainIcons" className='mainIcons'/>
          <img src={mainIconsMobile} alt="mainIcons" className='mainIconsMobile'/>
        </div>
        <div className='suppliers-text'>
          <h4>Principales Servicios</h4>
          <div className='suppliers-services'>
            <ol>
              <li>Trámite de licencias de nutrición animal y medicamentos ante el Instituto 
                Colombiano Agropecuario (ICA).</li>
              <li>Agente comercial para Sudamérica garantizando una introducción segura al 
                mercado.</li>
              <li>Auditorías en fábricas de China e India según parámetros del INVIMA o ICA, 
                presenciales o virtuales.</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='suppliers-second-services'>
        <div className='services-container-first'>
          <img src={estadisticas} alt="suppliersIcons" className='suppliersIcons'/>
          <p>
          Estadísticas de importación y análisis de mercado.
          </p>
        </div>
        <div className='services-container-second'>
          <img src={mercado} alt="suppliersIcons" className='suppliersIcons'/>
          <p>
          Desarrollo del mercado con ventas directas a clientes principales y acuerdos con 
          distribuidores locales.
          </p>
        </div>
        <div className='services-container-first'>
          <img src={documento} alt="suppliersIcons" className='suppliersIcons'/>
          <p>
          Aprobación de productos con soporte en documentación y pruebas de muestras.
          </p>
        </div>
        <div className='services-container-second'>
          <img src={seguro} alt="suppliersIcons" className='suppliersIcons'/>
          <p>
          Garantia de  ventas seguras y desarrollo eficiente del negocio.
          </p>
        </div>
        <div className='services-container-first'>
          <img src={financiacion} alt="suppliersIcons" className='suppliersIcons'/>
          <p>
          Financiación flexible de 30 a 180 días.
          </p>
        </div>
      </div>
    </section>
  )
}

export default SupliersServices