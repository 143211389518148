import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import PermissionWrapper from '../../context/PermissionWrapper';
import '../../appstyles/ClientsCSS/ClientsDetails.css'
import '../../appstyles/SuppliersCSS/SuppliersDetails.css'

function SuppliersDetails() {
  const axiosInstance = useAxiosPrivate();
  const { supplierId } = useParams();
  const [supplier, setSupplier] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    console.log('Fetching supplier details for ID:', supplierId);
    axiosInstance.get(`api/supplier/${supplierId}/`)
      .then(response => {
        console.log('Response data:', response.data);
        setSupplier(response.data);
      })
      .catch(error => {
        console.error('Failed to fetch supplier details', error);
      });
  }, [supplierId]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirmDelete = () => {
    axiosInstance.delete(`api/supplier/${supplierId}/`)
      .then(response => {
        //toast.success('supplier deleted successfully');
        navigate('/supplierslist');
      })
      .catch(error => {
        //toast.error('Failed to delete supplier');
        console.error('Failed to delete supplier', error);
      });
  };

  if (!supplier) {
    return(
    <div className='loading-container'>
      <p style={{fontFamily: 'Roboto, sans-serif'}}>Cargando...</p>
      <Spinner animation="border" variant="info" style={{width: '4.5rem', height: '4.5rem'}} />
    </div>
    );
  }

  const handleEditClick = () => {
    navigate(`/suppliersform/${supplierId}`); // Adjust the path as needed
  };

  return (
    <div className='maestros-details'>

      <h2>Detalles del Proveedor</h2>
      <hr />
      <div className='maestros-details-actions' style={{display: 'flex', flexDirection: 'row'}}>
        <h4>{supplier.supplier_name}</h4>

        <PermissionWrapper feature="proveedor" action="update">
          <button 
            className='btn btn-primary' 
            style={{backgroundColor: '#025C5A', borderColor: '#025C5A '}}
            onClick={handleEditClick}
          > 
            Editar
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
          </svg>
          </button>
        </PermissionWrapper>

        <PermissionWrapper feature="proveedor" action="delete">
          <button className='btn btn-danger' onClick={handleShow}>
            Eliminar
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
          </button>
        </PermissionWrapper>
        
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
            </svg>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Estas a punto de eliminar a {supplier.supplier_name} </h5>
          <p>¿Estás seguro de que lo deseas eliminar? Esta acción no podrá ser revertida</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
        </Modal>


      </div>

      <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className='first-col'>
        <p><strong>ID proveedor:</strong> {supplier.supplier_id} </p>
        <p><strong>Tipo de documento:</strong> {supplier.document_type} </p>
        <p><strong>Documento:</strong> {supplier.document_id} </p>
        <p><strong>País:</strong> {supplier.country} </p>
        <p><strong>Ciudad:</strong> {supplier.city} </p>
        <p><strong>Dirección:</strong> {supplier.address} </p>
        <p><strong>Teléfono:</strong> {supplier.phone} </p>
      </div>  
      <div className='second-col'>
        <p><strong>Página web:</strong> {supplier.web_page} </p>
        <p><strong>Categoría del proveedor:</strong> {supplier.category} </p>
        <p><strong>Estado del proveedor:</strong> {supplier.status} </p>
        <p><strong>Es financiador:</strong> {supplier.is_financer ? 'Sí' : 'No'} </p>
        <p><strong>Revisión área comercial:</strong> {supplier.reviewed ? 'Revisado' : 'No revisado'} </p>
        <p><strong>Aprobado área financiera:</strong> {supplier.approved ? 'Aprobado' : 'No aprobado'} </p>
        <p><strong>Última vez contactado:</strong> {supplier.last_contacted} </p>
      </div>
      </div>

      <hr />
      <div className='maestros-contactos'>
        <h4>Contactos</h4>
        <p className='nombre-contactos'><strong>Nombre área comercial:</strong> {supplier.name_commercial_contact} </p>
        <p><strong>Correo:</strong> {supplier.email_commercial_contact} </p>
        <p><strong>Teléfono:</strong> {supplier.phone_commercial_contact} </p>

        <p className='nombre-contactos'><strong>Nombre área financiera:</strong> {supplier.name_financial_contact} </p>
        <p><strong>Correo:</strong> {supplier.email_financial_contact} </p>
        <p><strong>Teléfono:</strong> {supplier.phone_financial_contact} </p>

        <p className='nombre-contactos'><strong>Nombre área técnica:</strong> {supplier.name_technical_contact} </p>
        <p><strong>Correo:</strong> {supplier.email_technical_contact} </p>
        <p><strong>Teléfono:</strong> {supplier.phone_technical_contact} </p>
      </div>

      <hr />
      <h4 style={{fontWeight:'700', fontSize: '1.5rem'}}>Información Bancaria</h4>
      <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className='first-col'>
        <p><strong>Nombre Banco:</strong> {supplier.bank_account} </p>
        <p><strong>Sucursal:</strong> {supplier.branch_office} </p>
        <p><strong>Número de cuenta:</strong> {supplier.account_number} </p>
        <p><strong>Tipo de cuenta:</strong> {supplier.account_type} </p>
        <p><strong>Número SWIFT:</strong> {supplier.swift_code} </p>
      </div>  
      <div className='second-col'>
        <p><strong>IBAN:</strong> {supplier.iban} </p>
        <p><strong>ABA:</strong> {supplier.aba} </p>
        <p><strong>Nombre banco intermediario:</strong> {supplier.inter_bank} </p>
        <p><strong>Número de cuenta intermediario:</strong> {supplier.inter_account_number} </p>
        <p><strong>Código SWIFT intermediario:</strong> {supplier.inter_swift_code} </p>
      </div>
      </div>

      <hr />
      <h4 style={{fontWeight:'700', fontSize: '1.5rem'}} >Información Financiera</h4>
      <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className='first-col'>
        <p><strong>Fecha del último corte fiscal:</strong> {supplier.date_last_tax_year} </p>
        <p><strong>Ingresos (USD):</strong> {supplier.income} </p>
        <p><strong>Egresos (USD):</strong> {supplier.expenses} </p>
        <p><strong>Activos (USD):</strong> {supplier.active_income} </p>
      </div>  
      <div className='second-col'>
        <p><strong>Pasivos (USD):</strong> {supplier.passive_income} </p>
        <p><strong>Patrimonio (USD):</strong> {supplier.patrimony} </p>
        <p><strong>Ganancias netas (USD):</strong> {supplier.net_profit} </p>
      </div>
      </div>

      <hr />
      <div className='maestros-contactos'>
        <h4>Sistema de gestión de calidad o certificaciones</h4>
        <p><strong>Tipo:</strong> {supplier.qms_type} </p>
        <p><strong>Nombre:</strong> {supplier.qms_name} </p>
      </div>
    
    </div>
  )
}

export default SuppliersDetails