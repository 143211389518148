import React, {useState} from 'react';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import {Button, Spinner} from 'react-bootstrap'

const ExcelProducts = () => {
    const axiosInstance = useAxiosPrivate()
    const [loading, setLoading] = useState(false);

    const handleExport = async () => {
        setLoading(true);
        try {
        const response = await axiosInstance.get('api/product/export-excel/export_excel/', 
            {
            headers: {'content-type': 'blob'},
            responseType: 'arraybuffer',
            maxRedirects: 2,
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'productos.xlsx');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        } catch (error) {
        console.error('Error exporting Excel file:', error);
        console.log(error)
        } finally { 
            setLoading(false);
        }
    };

    return (
        <div>
            {loading ? (
                <Button
                    style={{
                        border: 'solid 2px #024E4C',
                        backgroundColor: '#056664',
                    }} 
                    disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    descargando...
                </Button>
            ) : (
                <Button
                    onClick={handleExport}
                    style={{
                        border: 'solid 2px #024E4C',
                        backgroundColor: '#056664',
                    }}
                >
                    Exportar Excel
                </Button>
            )}
        </div>
    );
};

export default ExcelProducts;
