import React from 'react'
import LogisticsServices from '../components/LogisticsServices'
/*Images-Icons*/
import Hero from '../assets/imagenes/Homepage2.png'
import Vector from '../assets/vectores/Vector 32blue vector.png'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Link } from 'react-router-dom'
import '../styles/LandingPage.css'

function LandingPage() {
  return (
    <section className='LandingPage'>
    <section className="hero" style={{backgroundImage: `url(${Hero})`}}>
        <div className="content">
            <h1 className="text1">Facilitamos el comercio internacional desde hace más de 15 años.</h1>
            <img src={Vector} alt="Vector" className='vector'/>
            <p className="text2">Conectamos empresas globales líderes para impulsar la comercialización 
                de materias primas y productos en nutrición animal, veterinaria, farmacéutica e industrial.</p>
            <Link to="/productos" className='productos-container'>
                <button className="productos">Descubre Nuestros Productos <ArrowForwardOutlinedIcon style={{ fontSize: 20 }} /> </button>
            </Link> 
        </div>
    </section>
    <div className="contentMobile">
        <h1 className="text1">Facilitamos el comercio internacional desde hace más de 15 años.</h1>
        <p className="text2">Conectamos empresas globales líderes para impulsar la comercialización 
            de materias primas y productos en nutrición animal, veterinaria, farmacéutica e industrial.</p>
        <Link to="/productos" className='productos-container'>
            <button className="productos"> Descubre Nuestros Productos </button>
        </Link> 
    </div>   
    <LogisticsServices />
    <section style={{display: 'flex', justifyContent: 'center', margin: '20px'}}>
        <div className='degraded-container'>
        <div className='explora'>
            <div>
                <h3 style={{fontFamily: 'Outfit, sans-serif', color: '#421831', fontWeight: '700'}}>¡Explora nuestros servicios!</h3>
                <p>
                Como representantes comerciales para Colombia y Sudamérica, nos enorgullece ser socios estratégicos 
                de proveedores aliados, garantizando un mercado seguro, sólido y rentable.
                </p>
            </div>
            <div>
                <h4>Servicio a Clientes</h4>
                <p>
                Comunicación rápida para lograr una óptima relación comercial, con los mejores precios del mercado.
                </p>
                <h4>Servicio a Proveedores</h4>
                <p>
                Asesoría y trámite de licencias de nutrición animal y medicamentos ante el ICA y el INVIMA.
                </p>
            </div>
        </div>
        <div className='servicios-div' style={{width: '100%', display: 'flex', margin: '0px 40px'}}>
            <Link to="/servicios" className='NuestrosServicios-container'>
                <button className="NuestrosServicios">Nuestros Servicios</button>
            </Link>
        </div>
        </div>
    </section>
    </section>
  )
}

export default LandingPage