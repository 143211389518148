import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import useAxiosPrivate from '../../context/hooks/useAxiosPrivate';
import '../../appstyles/ClientsCSS/ClientsForm.css';

// Validation schema
const RequiredString = Yup.string().required('Este campo es obligatorio');

const userSchema = Yup.object().shape({
    auth_user: Yup.object().shape({
        username: Yup.string()
        .matches(/^\S{1,15}$/, 'El nombre de usuario no debe contener espacios y debe tener un máximo de 15 caracteres.')
        .required('El nombre de usuario es obligatorio.'),
    }),
    document_id: Yup.string()
    .required('Este campo es obligatorio')
    .matches(/^\+?\d+$/, 'Debe ser un número'),
    document_type: RequiredString,
    role: RequiredString,
    email: Yup.string().email('Formato invalido').required('Este campo es obligatorio'),
});

const UserForm = () => {
    const axiosInstance = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    //const {userClientId} = useParams();
    const navigate = useNavigate();

    const defaultValues = {
        document_type: 'CC',
        role: 'CLI',
    };

    const { register, handleSubmit, reset, setError, formState: { errors } } = useForm({
        resolver: yupResolver(userSchema),
        defaultValues
    });

    /*
    useEffect(() => {
        if (userClientId) {
          // Fetch user data for editing
          const fetchUserData = async () => {
            try {
              const response = await axiosInstance.get(`/api/user/${userClientId}/`);
              reset(response.data);
            } catch (error) {
              console.error('Error fetching user data:', error);
            }
          };
    
          fetchUserData(userClientId);
        }
    }, [userClientId, reset, axiosInstance]);  
    

    const onSubmit = async (data) => {
        try {

            if (userClientId) {
                // Update existing user
                const response = await axiosInstance.put(`api/user/${userClientId}/`, data); 
                console.log("User updated successfully!", response.data);
        
                navigate(`/settings/userdetails/${userClientId}`)
        
            } else {
                // Create new user
                const response = await axiosInstance.post('api/user/', data);
                console.log("User created successfully!", response.data);

                reset();
                setTimeout(() => {
                    navigate(`/settings`);
                }, 1000); 
            }

        } catch (error) {
            console.error(error);
        }
    };
    */

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            // Create new user
            const response = await axiosInstance.post('api/user/', data);
            console.log("User created successfully!", response.data);

            reset();
            setTimeout(() => {
                navigate(`/settings`);
            }, 1000); 
        } catch (error) {
            if (error.response) {
                if (error.response.status === 500 && error.response.data.includes("Duplicate entry")) {
                  setError('auth_user.username', {
                    type: 'manual',
                    message: 'Este nombre de usuario ya existe. Por favor, elige otro.',
                  });
                } else {
                  console.error('An error occurred:', error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='maestros-form'>
        <h2> Crear Usuario: Cliente </h2> 

        <Form onSubmit={handleSubmit(onSubmit)}>
        <hr />
        <h5>Información del Usuario</h5>
          <Row>
            {/* Primera Columna*/}
            <Col>

            <Form.Group controlId="role">
                <Form.Label>Cargo</Form.Label>
                <Form.Select aria-label="Default select example" {...register('role')} isInvalid={!!errors.role} disabled>
                  <option value="ADMIN">Administrador</option>
                  <option value="DEV">Desarrollador</option>
                  <option value="CEO">CEO</option>
                  <option value="DTCA">Director de Calidad</option>
                  <option value="DTLO">Director de Logística</option>
                  <option value="DTFI">Director Financiero</option>
                  <option value="ANAD">Analista Administrativo</option>
                  <option value="DTAD">Director Administrativo</option>
                  <option value="DTCO">Director Comercial</option>
                  <option value="ASCO">Asesor Comercial</option>
                  <option value="ANCO">Analista de Compras</option>
                  <option value="AUBO">Auxiliar de Bodega</option>
                  <option value="CLI">Cliente</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.role?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="username">
                <Form.Label>Nombre de Usuario</Form.Label>
                <Form.Control 
                    type="text" 
                    {...register('auth_user.username')} 
                    isInvalid={!!errors.auth_user?.username} 
                />
                <Form.Control.Feedback type="invalid">
                    {errors.auth_user?.username?.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control 
                    type="email" 
                    {...register('email')} 
                    isInvalid={!!errors.email} 
                />
                <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                </Form.Control.Feedback>
            </Form.Group>
    
            </Col>

            {/* Segunda Columna*/}
            <Col>
            <Form.Group controlId="document_type">
              <Form.Label>Tipo de documento</Form.Label>
              <Form.Select 
                aria-label="Default select example" 
                {...register('document_type')} 
                isInvalid={!!errors.document_type}
            >
                  <option value="NIT">NIT</option>
                  <option value="CC">Cedula de Ciudadania (CC)</option>
                  <option value="TAX ID">Tax ID</option>
                  <option value="OTHER">Otro</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                      {errors.document_type?.message}
              </Form.Control.Feedback>
            </Form.Group>
    
            <Form.Group controlId="document_id">
              <Form.Label>Número de documento</Form.Label>
              <Form.Control 
                type="text" 
                {...register('document_id')} 
                isInvalid={!!errors.document_id} 
            />
              <Form.Control.Feedback type="invalid">
                    {errors.document_id?.message}
              </Form.Control.Feedback>
            </Form.Group>

            </Col>
        </Row>

        <Button type="submit">
        {loading ? (
            <>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Cargando...
            </>
        ) : (
            'Crear Usuario'
        )}
        </Button>



        </Form>

        </div>
    );
};

export default UserForm;