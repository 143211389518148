import React from 'react'
import Facebook from '@mui/icons-material/Facebook';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import '../styles/Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
    <div className="footer-content">
        <div className="footer-section-about">
            <h3 className="logo-text" style={{fontWeight: '700'}}>Nutrition and Chemical Market</h3>
            <p>
            Empresa dedicada a facilitar el comercio internacional, especializada en impulsar
            la comercialización de materias primas para los sectores de nutrición animal, 
            veterinaria, farmacéutica e industrial.
            </p>
            <div className="socials">
                <Link to="https://www.facebook.com/nutrimarketsas">
                    <div className="facebook-container">
                        <Facebook />
                    </div>
                </Link>
            </div>
        </div>

        <div className="footer-section mobile-hide">
            <ul>
                <li><Link to="/nosotros">Nosotros</Link></li>
                <li><Link to="/productos">Productos</Link></li>
                <li><Link to="/servicios">Servicios</Link></li>
                <li><Link to="/contacto">Contacto</Link></li>
            </ul>
        </div>

        <div className="contact">
                <li> <Email style={{ fontSize: 20, marginRight: 5 }} /> &nbsp; comercial@nutrimarketchemicals.com</li>
                <li> <Phone style={{ fontSize: 20, marginRight: 5 }} /> &nbsp; +57 317 5178521</li>
        </div>
    </div>
    
    <div className="footer-bottom">
        <hr />
        <div className="footer-bottom">
            &copy; 2024 nutritionmarket.net
        </div>
    </div>
    </footer>
  )
}

export default Footer