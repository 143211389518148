import React from 'react'
import OfficesAbout from '../components/OfficesAbout'
import TeamAbout from '../components/TeamAbout'
//Images-Icons
import about from "../assets/vectores/about-image.svg"
import map from '../assets/imagenes/about-us-1.png'
//Styles
import '../styles/Nosotros/About.css'


function About() {
  return (
    <>
        <div className="title-container">
            <div className='txt-title'>
                <h2 style={{fontFamily: 'Outfit, sans-serif', fontWeight: '700', margin: '20px' }}>NOSOTROS</h2>
            </div>
            <div className='about-img-container'>
                <img src={about} alt="about" className='about'/>
            </div>
        </div>
        <section className="aboutUs-container">
            <div className='map-container' >
                <img src={map} alt="map" className='map'/>
            </div>
            <div className='quienes-somos'>
                <h6 style={{fontFamily: 'Outfit, sans-serif', fontWeight: '600', color: '#017C79'}}> ¿QUIENES SOMOS?</h6>
                <h4 style={{fontFamily: 'Roboto, sans-serif', color: '#000', fontWeight: '600'}}>Fundada en 2005, somos una empresa privada comprometida con
                        la promoción de negocios a nivel nacional e internacional en
                        busca de la globalización.</h4>
                <p style={{fontFamily: 'Roboto, sans-serif', color: '#000', fontWeight:'400'}}>Actuamos como aliados comerciales entre clientes y fábricas en
                        todo el mundo, buscando mejorar las relaciones comerciales y 
                        facilitar los negocios para todas las partes involucradas.</p>
            </div>
        </section>
        <OfficesAbout />
        <TeamAbout />
    </>
  )
}

export default About